<template>
  <div class="app">
    <base-header v-if="!router.meta.hideHeader" />
    <router-view class="router-view" />
    <base-footer v-if="!router.meta.hideFooter" />
  </div>
</template>

<script setup lang="ts">
import BaseHeader from "@/components/BaseHeader";
import BaseFooter from "@/components/BaseFooter";
import "./styles/app.scss";
import { useRoute } from "vue-router";
const router = useRoute();
</script>
<style lang="scss">
.app {
  margin: 0 auto;
  max-width: 1290px;
}
@media (max-width: 768px) {
  .app {
    padding: 7px;
  }
}
</style>
