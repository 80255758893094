import { createStore } from "vuex";
import axios from "axios";

export default createStore({
  state: { token: localStorage.getItem("token") || "", status: "" },
  getters: { isAuthenticated: (state) => !!state.token },
  mutations: {
    auth_success(state, token) {
      state.token = token;
      state.status = "success";
    },
    logout(state) {
      state.token = "";
      state.status = "";
    },
  },
  actions: {
    login({ commit }, user) {
      return new Promise((resolve, reject) => {
        axios
          .post("https://api.brewsell.com/auth/login", user)
          .then((response) => {
            const token = response.data.token;
            localStorage.setItem("token", token);
            commit("auth_success", token);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    logout({ commit }) {
      localStorage.removeItem("token");
      commit("logout");
    },
  },
  modules: {},
});
